var $ = jQuery.noConflict();
var $window = jQuery(window);

jQuery(function ($) { // use jQuery code inside this to avoid "$ is not defined" error
	$('.btn_loadmore').click(function () {

		var button = $(this),
			data = {
				'action': 'loadmore',
				'query': $(this).attr('data-cat'), // that's how we get params from wp_localize_script() function
				'page': $(this).attr('data-page')
			};

		$.ajax({ // you can also use $.post here
			url: blulegal_loadmore_params.ajaxurl, // AJAX handler
			data: data,
			type: 'POST',
			beforeSend: function (xhr) {
				button.addClass('disabled'); // change the button text, you can also add a preloader image
			},
			success: function (data) {
				if (data) {
					button.removeClass('disabled'); // insert new posts
					button.closest('.acf-block-archiwum').find('.archiwum-list').append(data);
					let currentPage = button.attr('data-page')
					button.attr('data-page', parseInt(currentPage)+1);

					if (button.attr('data-page') == button.attr('data-max'))
						button.remove(); // if last page, remove the button

					// you can also fire the "post-load" event here if you use a plugin that requires it
					// $( document.body ).trigger( 'post-load' );
				} else {
					button.remove(); // if no data, remove the button as well
				}
			}
		});
	});

	$('.archiwum-select').on('select2:select', function (e) {
		var data = e.params.data;
		var button = $(this);
		console.log(data.id);
		var data = {
			'action': 'changeOrder',
			'cat': data.id.split('-')[0], // that's how we get params from wp_localize_script() function
			'order': data.id.split('-')[1]
		};
		$.ajax({ // you can also use $.post here
			url: blulegal_loadmore_params.ajaxurl, // AJAX handler
			data: data,
			type: 'POST',
			beforeSend: function (xhr) {
				// button.addClass('disabled'); // change the button text, you can also add a preloader image
			},
			success: function (data) {
				if (data) {
					button.closest('.acf-block-archiwum').find('.archiwum-list').html(data);
					console.log('podmieniono');
				} else {
					// button.remove(); // if no data, remove the button as well
				}
			}
		});
	});

});
